import { gql } from '@apollo/client';
import ProjectFragment from '../Project/ProjectFragment';
import InvoiceRecordFragment from './InvoiceRecordFragment';

const ProjectInvoiceFragment = gql`
  fragment ProjectInvoiceData on ProjectInvoice {
    id
    identifier
    project {
      ...ProjectData
    }
    purchaseOrder {
      id
      identifier
      metaData
    }
    status
    currency
    billTo
    billFrom
    startDate
    endDate
    date
    subTotal
    vat
    billFromVat
    billToVat
    discount
    total
    totalPaid
    taxLabel
    shippingInfo
	  paymentInfo
    dueDate
    invoiceUrl
    invoiceAdditionalInfo
    invoiceUnitType
    companyRegistrationNumber
    legalEntityName
    invoiceFooter
    invoiceRecords {
      ...InvoiceRecordData
    }
  }
  ${ProjectFragment}
  ${InvoiceRecordFragment}
`;

export default ProjectInvoiceFragment;
