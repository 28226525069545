import { gql } from '@apollo/client';

const ConsultantFunctionalRoleFragment = gql`
  fragment ConsultantFunctionalRoleData on ConsultantFunctionalRole {
    name
    group
  }
`;

export default ConsultantFunctionalRoleFragment;
