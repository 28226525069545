export const namedOperations = {
  Query: {
    adminWorkspaces: 'adminWorkspaces',
    adminWorkspace: 'adminWorkspace',
    clientPortalConsultants: 'clientPortalConsultants',
    clientPortalConsultant: 'clientPortalConsultant',
    clientPortalConsultantProjectRoles: 'clientPortalConsultantProjectRoles',
    clientPortalClientContractTypes: 'clientPortalClientContractTypes',
    clientPortalProjects: 'clientPortalProjects',
    clientPortalProjectsManagers: 'clientPortalProjectsManagers',
    clientPortalProject: 'clientPortalProject',
    clientPortalProjectDeliverables: 'clientPortalProjectDeliverables',
    clientPortalProjectDeliverable: 'clientPortalProjectDeliverable',
    clientPortalProjectContracts: 'clientPortalProjectContracts',
    clientPortalProjectRoles: 'clientPortalProjectRoles',
    clientPortalTimesheets: 'clientPortalTimesheets',
    clientPortalWeekTimesheetsForRole: 'clientPortalWeekTimesheetsForRole',
    clientPortalCompanyInfo: 'clientPortalCompanyInfo',
    consultantBaseProfile: 'consultantBaseProfile',
    cpJobOpportunitiesLocations: 'cpJobOpportunitiesLocations',
    cpJobOpportunities: 'cpJobOpportunities',
    cpJobOpportunity: 'cpJobOpportunity',
    cpAvailableOpenForBookingProjects: 'cpAvailableOpenForBookingProjects',
    cpAvailableOpenForBookingProject: 'cpAvailableOpenForBookingProject',
    consultantFunctionalRoles: 'consultantFunctionalRoles',
    consultantIndustries: 'consultantIndustries',
    consultant: 'consultant',
    talentMarketplaceConsultant: 'talentMarketplaceConsultant',
    consultantBusinessInfo: 'consultantBusinessInfo',
    consultantAvailability: 'consultantAvailability',
    consultantWeeklyEngagementStatus: 'consultantWeeklyEngagementStatus',
    checkInvite: 'checkInvite',
    workspacePublicSettings: 'workspacePublicSettings',
    me: 'me',
    isEmailAvailable: 'isEmailAvailable',
    projectInvoices: 'projectInvoices',
    invoices: 'invoices',
    invoice: 'invoice',
    getInvoiceIdentifier: 'getInvoiceIdentifier',
    invoiceData: 'invoiceData',
    invoiceRoleRecord: 'invoiceRoleRecord',
    invoiceRoleRecords: 'invoiceRoleRecords',
    purchaseOrder: 'purchaseOrder',
    dailyTimesheetsForRoles: 'dailyTimesheetsForRoles',
    workspaceClients: 'workspaceClients',
    workspaceClientUsers: 'workspaceClientUsers',
    workspaceUsersByClient: 'workspaceUsersByClient',
    clientIndustries: 'clientIndustries',
    clientContracts: 'clientContracts',
    clientContractTypes: 'clientContractTypes',
    clientContract: 'clientContract',
    isClientNameAvailable: 'isClientNameAvailable',
    workspaceClient: 'workspaceClient',
    clientContacts: 'clientContacts',
    clientContact: 'clientContact',
    clientNotes: 'clientNotes',
    clientProjects: 'clientProjects',
    clientLegalEntities: 'clientLegalEntities',
    consultants: 'consultants',
    talentMarketplace: 'talentMarketplace',
    consultantsAutocomplete: 'consultantsAutocomplete',
    isConsultantEmailAvailable: 'isConsultantEmailAvailable',
    consultantContracts: 'consultantContracts',
    consultantProjectRoles: 'consultantProjectRoles',
    consultantProjectsOverview: 'consultantProjectsOverview',
    consultantDocuments: 'consultantDocuments',
    consultantDocument: 'consultantDocument',
    consultantDocumentTypes: 'consultantDocumentTypes',
    consultantGroups: 'consultantGroups',
    consultantNotes: 'consultantNotes',
    consultantLinks: 'consultantLinks',
    consultantLink: 'consultantLink',
    checkConsultantDuplicates: 'checkConsultantDuplicates',
    consultantContract: 'consultantContract',
    contractTypes: 'contractTypes',
    consultantContractTypes: 'consultantContractTypes',
    initialConsultantDAContractInfo: 'initialConsultantDAContractInfo',
    serviceAgreementRef: 'serviceAgreementRef',
    docusignConnectionState: 'docusignConnectionState',
    addableConsultantContractTypes: 'addableConsultantContractTypes',
    addableProjectContractTypes: 'addableProjectContractTypes',
    clientContractSenderViewUrl: 'clientContractSenderViewUrl',
    consultantContractSenderViewUrl: 'consultantContractSenderViewUrl',
    workspaceContractTemplates: 'workspaceContractTemplates',
    workspaceContractTemplate: 'workspaceContractTemplate',
    documentTypes: 'documentTypes',
    timesheetReport: 'timesheetReport',
    jobOpportunities: 'jobOpportunities',
    jobOpportunity: 'jobOpportunity',
    publicJobOpportunitiesLocations: 'publicJobOpportunitiesLocations',
    publicJobOpportunities: 'publicJobOpportunities',
    publicJobOpportunity: 'publicJobOpportunity',
    jobApplicationStatuses: 'jobApplicationStatuses',
    countWorkspaceConsultants: 'countWorkspaceConsultants',
    jobApplications: 'jobApplications',
    jobApplication: 'jobApplication',
    workspaceLegalEntities: 'workspaceLegalEntities',
    workspaceProjects: 'workspaceProjects',
    workspaceProjectsAutocomplete: 'workspaceProjectsAutocomplete',
    usersAssignedToWorkspaceProjects: 'usersAssignedToWorkspaceProjects',
    projectsOverview: 'projectsOverview',
    workspaceProject: 'workspaceProject',
    projectRoles: 'projectRoles',
    projectRole: 'projectRole',
    isProjectRoleReassignable: 'isProjectRoleReassignable',
    consultantsAssignedToProject: 'consultantsAssignedToProject',
    projectDeliverables: 'projectDeliverables',
    projectDeliverable: 'projectDeliverable',
    projectConsultantContracts: 'projectConsultantContracts',
    projectClientContracts: 'projectClientContracts',
    consultantsToBeOnboarded: 'consultantsToBeOnboarded',
    projectTimesheetPlanner: 'projectTimesheetPlanner',
    projectPeriodAffectsTimesheet: 'projectPeriodAffectsTimesheet',
    projectRolePeriodAffectsTimesheet: 'projectRolePeriodAffectsTimesheet',
    projectScenarios: 'projectScenarios',
    projectScenario: 'projectScenario',
    projectExpenses: 'projectExpenses',
    expensesOverview: 'expensesOverview',
    projectRolesOverview: 'projectRolesOverview',
    budgetOverview: 'budgetOverview',
    projectNotes: 'projectNotes',
    projectRoleWorkPlan: 'projectRoleWorkPlan',
    hasInvoicedActuals: 'hasInvoicedActuals',
    projectMilestones: 'projectMilestones',
    projectMilestone: 'projectMilestone',
    workspaceSettings: 'workspaceSettings',
    workspaceLocations: 'workspaceLocations',
    consultantLevels: 'consultantLevels',
    expenseCategories: 'expenseCategories',
    countExpenseCaterogyEntries: 'countExpenseCaterogyEntries',
    workspaceMemberUsersAndInvites: 'workspaceMemberUsersAndInvites',
    userProfile: 'userProfile',
    workspaceInvoiceHistoricalRecords: 'workspaceInvoiceHistoricalRecords',
    workspaceCurrencyRates: 'workspaceCurrencyRates',
    workspacePublicHolidays: 'workspacePublicHolidays',
    workspaceUsers: 'workspaceUsers',
    utilisation: 'utilisation',
    weeklyEngagementStatus: 'weeklyEngagementStatus',
    weeklyEngagementApproveStatus: 'weeklyEngagementApproveStatus',
    isWorkspaceAvailable: 'isWorkspaceAvailable',
    countries: 'countries'
  },
  Mutation: {
    adminLogin: 'adminLogin',
    sendConsultantTimesheetsEmail: 'sendConsultantTimesheetsEmail',
    adminRunCronJob: 'adminRunCronJob',
    runScript: 'runScript',
    getEnvs: 'getEnvs',
    updateEmailWhitelist: 'updateEmailWhitelist',
    resetWorkspace: 'resetWorkspace',
    reaggregateProjectsData: 'reaggregateProjectsData',
    seedEntity: 'seedEntity',
    updateWorkspaceInvoiceNotificationEmailList: 'updateWorkspaceInvoiceNotificationEmailList',
    adminCreateWorkspace: 'adminCreateWorkspace',
    adminRemoveWorkspace: 'adminRemoveWorkspace',
    approveClientTimesheetsBulk: 'approveClientTimesheetsBulk',
    reviewClientTimesheets: 'reviewClientTimesheets',
    updateConsultantAvailabilityByToken: 'updateConsultantAvailabilityByToken',
    createJobApplication: 'createJobApplication',
    updateJobApplication: 'updateJobApplication',
    cpJoinOpenForBookingProject: 'cpJoinOpenForBookingProject',
    updateConsultantProfile: 'updateConsultantProfile',
    signupConsultantUser: 'signupConsultantUser',
    submitDailyWeekEngagementCP: 'submitDailyWeekEngagementCP',
    sendConsultantMontlyEngagementSummary: 'sendConsultantMontlyEngagementSummary',
    loginWithPassword: 'loginWithPassword',
    requestPasswordReset: 'requestPasswordReset',
    confirmPasswordReset: 'confirmPasswordReset',
    loginWithLinkedInCode: 'loginWithLinkedInCode',
    updateConsultantBusinnessInfo: 'updateConsultantBusinnessInfo',
    removeConsultantBusinnessInfo: 'removeConsultantBusinnessInfo',
    updateConsultantAvailability: 'updateConsultantAvailability',
    removeConsultantCV: 'removeConsultantCV',
    removeConsultantAvatar: 'removeConsultantAvatar',
    signupWithNewWorkspace: 'signupWithNewWorkspace',
    onboardExistingUser: 'onboardExistingUser',
    onboardNewUser: 'onboardNewUser',
    verifyEmail: 'verifyEmail',
    removeProjectInvoice: 'removeProjectInvoice',
    updateProjectInvoice: 'updateProjectInvoice',
    createGeneratedInvoice: 'createGeneratedInvoice',
    createProjectInvoice: 'createProjectInvoice',
    sendPurchaseOrder: 'sendPurchaseOrder',
    sendPurchaseOrders: 'sendPurchaseOrders',
    createWorkspaceClient: 'createWorkspaceClient',
    updateWorkspaceClient: 'updateWorkspaceClient',
    removeWorkspaceClient: 'removeWorkspaceClient',
    assignUserToClient: 'assignUserToClient',
    createClientContact: 'createClientContact',
    updateClientContact: 'updateClientContact',
    removeClientContact: 'removeClientContact',
    createClientNote: 'createClientNote',
    updateClientNote: 'updateClientNote',
    pinClientNote: 'pinClientNote',
    removeClientNote: 'removeClientNote',
    createClientLegalEntity: 'createClientLegalEntity',
    updateClientLegalEntity: 'updateClientLegalEntity',
    removeClientLegalEntity: 'removeClientLegalEntity',
    createConsultant: 'createConsultant',
    removeConsultantDocument: 'removeConsultantDocument',
    createConsultantDocument: 'createConsultantDocument',
    updateConsultantDocument: 'updateConsultantDocument',
    verifyConsultantBusinessInfo: 'verifyConsultantBusinessInfo',
    updateConsultantCV: 'updateConsultantCV',
    updateConsultant: 'updateConsultant',
    disableConsultant: 'disableConsultant',
    enableConsultant: 'enableConsultant',
    createConsultantNote: 'createConsultantNote',
    updateConsultantNote: 'updateConsultantNote',
    pinConsultantNote: 'pinConsultantNote',
    removeConsultantNote: 'removeConsultantNote',
    createConsultantLink: 'createConsultantLink',
    updateConsultantLink: 'updateConsultantLink',
    removeConsultantLink: 'removeConsultantLink',
    createConsultantTag: 'createConsultantTag',
    addConsultantToMarketplace: 'addConsultantToMarketplace',
    removeConsultantFromMarketplace: 'removeConsultantFromMarketplace',
    addConsultantToFavorites: 'addConsultantToFavorites',
    removeConsultantFromFavorites: 'removeConsultantFromFavorites',
    requestConsultantContact: 'requestConsultantContact',
    removeConsultantContract: 'removeConsultantContract',
    voidConsultantContract: 'voidConsultantContract',
    createConsultantContract: 'createConsultantContract',
    updateConsultantContract: 'updateConsultantContract',
    createClientContract: 'createClientContract',
    updateClientContract: 'updateClientContract',
    removeClientContract: 'removeClientContract',
    voidClientContract: 'voidClientContract',
    generateDraftContract: 'generateDraftContract',
    createJobOpportunity: 'createJobOpportunity',
    removeJobOpportunity: 'removeJobOpportunity',
    updateJobOpportunity: 'updateJobOpportunity',
    updateJobOpportunityStatus: 'updateJobOpportunityStatus',
    createJobApplicationStatus: 'createJobApplicationStatus',
    removeJobApplicationStatus: 'removeJobApplicationStatus',
    updateJobApplicationStatus: 'updateJobApplicationStatus',
    publishJobOpportunity: 'publishJobOpportunity',
    sharePublicJobOpportunity: 'sharePublicJobOpportunity',
    createProject: 'createProject',
    assignUserToProject: 'assignUserToProject',
    assignEngagementSupportToProject: 'assignEngagementSupportToProject',
    assignProjectClientManager: 'assignProjectClientManager',
    updateProjectPeriod: 'updateProjectPeriod',
    updateProject: 'updateProject',
    removeProject: 'removeProject',
    updateProjectOKRs: 'updateProjectOKRs',
    updateProjectStage: 'updateProjectStage',
    closeProject: 'closeProject',
    loseProject: 'loseProject',
    cloneProject: 'cloneProject',
    createProjectDeliverable: 'createProjectDeliverable',
    updateProjectDeliverable: 'updateProjectDeliverable',
    removeProjectDeliverable: 'removeProjectDeliverable',
    createProjectRole: 'createProjectRole',
    updateProjectRole: 'updateProjectRole',
    removeProjectRole: 'removeProjectRole',
    setupProjectOnboarding: 'setupProjectOnboarding',
    updateProjectTimesheetAdvanced: 'updateProjectTimesheetAdvanced',
    updateProjectDailyTimesheet: 'updateProjectDailyTimesheet',
    updateProjectRoleCosts: 'updateProjectRoleCosts',
    setProjectVisibility: 'setProjectVisibility',
    setClientEngagedProjectsVisibility: 'setClientEngagedProjectsVisibility',
    createProjectScenario: 'createProjectScenario',
    updateProjectScenario: 'updateProjectScenario',
    removeProjectScenario: 'removeProjectScenario',
    setActiveProjectScenario: 'setActiveProjectScenario',
    updateProjectLock: 'updateProjectLock',
    createProjectExpense: 'createProjectExpense',
    updateProjectExpense: 'updateProjectExpense',
    removeProjectExpense: 'removeProjectExpense',
    updateProjectBudget: 'updateProjectBudget',
    updateProjectType: 'updateProjectType',
    createProjectNote: 'createProjectNote',
    updateProjectNote: 'updateProjectNote',
    pinProjectNote: 'pinProjectNote',
    removeProjectNote: 'removeProjectNote',
    updateProjectWinProbability: 'updateProjectWinProbability',
    updateProjectDiscount: 'updateProjectDiscount',
    updateProjectRoleWorkPlan: 'updateProjectRoleWorkPlan',
    approveTimesheetsBulk: 'approveTimesheetsBulk',
    updateProjectIsOpenForBooking: 'updateProjectIsOpenForBooking',
    createProjectMilestone: 'createProjectMilestone',
    updateProjectMilestone: 'updateProjectMilestone',
    removeProjectMilestone: 'removeProjectMilestone',
    updateWorkspaceSettings: 'updateWorkspaceSettings',
    removeWorkspaceLogo: 'removeWorkspaceLogo',
    createWorkspaceLocation: 'createWorkspaceLocation',
    updateWorkspaceLocation: 'updateWorkspaceLocation',
    removeWorkspaceLocation: 'removeWorkspaceLocation',
    createConsultantLevel: 'createConsultantLevel',
    updateConsultantLevel: 'updateConsultantLevel',
    removeConsultantLevel: 'removeConsultantLevel',
    createConsultantGroup: 'createConsultantGroup',
    updateConsultantGroup: 'updateConsultantGroup',
    removeConsultantGroup: 'removeConsultantGroup',
    createExpenseCategory: 'createExpenseCategory',
    updateExpenseCategory: 'updateExpenseCategory',
    removeExpenseCategory: 'removeExpenseCategory',
    inviteWorkspaceUser: 'inviteWorkspaceUser',
    resendUserInvite: 'resendUserInvite',
    revokeUserAccess: 'revokeUserAccess',
    updateUserAccessLevel: 'updateUserAccessLevel',
    updateUserProfile: 'updateUserProfile',
    updateConsultantPortalWorkspaceSettings: 'updateConsultantPortalWorkspaceSettings',
    removeWelcomePageLogo: 'removeWelcomePageLogo',
    removeWorkspaceBackgroundImage: 'removeWorkspaceBackgroundImage',
    updateWorkspaceCurrencyRate: 'updateWorkspaceCurrencyRate',
    createWorkspaceLegalEntity: 'createWorkspaceLegalEntity',
    updateWorkspaceLegalEntity: 'updateWorkspaceLegalEntity',
    removeWorkspaceLegalEntity: 'removeWorkspaceLegalEntity',
    updateWorkspaceContractTemplate: 'updateWorkspaceContractTemplate',
    createWorkspaceContractTemplate: 'createWorkspaceContractTemplate',
    updateWorkspaceContractTemplateStatus: 'updateWorkspaceContractTemplateStatus',
    removeJobBoardLogo: 'removeJobBoardLogo',
    removeJobBoardBackgroundImage: 'removeJobBoardBackgroundImage',
    updateWorkspacePublicHolidays: 'updateWorkspacePublicHolidays',
    submitDailyWeekEngagement: 'submitDailyWeekEngagement',
    approveWeeklyEngagement: 'approveWeeklyEngagement'
  },
  Fragment: {
    AdminPortalWorkspaceData: 'AdminPortalWorkspaceData',
    AdminPortalAuthPayloadData: 'AdminPortalAuthPayloadData',
    ClientPortalClientContract: 'ClientPortalClientContract',
    ClientPortalCompanyInfoData: 'ClientPortalCompanyInfoData',
    ClientPortalConsultantFullData: 'ClientPortalConsultantFullData',
    ClientPortalProjectDetails: 'ClientPortalProjectDetails',
    ClientPortalProjectsTable: 'ClientPortalProjectsTable',
    ClientPortalProjectDeliverableData: 'ClientPortalProjectDeliverableData',
    ClientPortalProjectDeliverablesTable: 'ClientPortalProjectDeliverablesTable',
    ClientPortalConsultantProjectRole: 'ClientPortalConsultantProjectRole',
    ClientPortalProjectRoleData: 'ClientPortalProjectRoleData',
    AvailibleOpenForBookingProjectData: 'AvailibleOpenForBookingProjectData',
    WorkspaceConsultantBaseProfileData: 'WorkspaceConsultantBaseProfileData',
    AuthPayloadData: 'AuthPayloadData',
    ConsultantBaseData: 'ConsultantBaseData',
    ConsultantFullData: 'ConsultantFullData',
    DatePeriodData: 'DatePeriodData',
    ConsultantAvailabilityData: 'ConsultantAvailabilityData',
    ConsultantBusinessInfoData: 'ConsultantBusinessInfoData',
    ConsultantFunctionalRoleData: 'ConsultantFunctionalRoleData',
    ConsultantGroupBaseData: 'ConsultantGroupBaseData',
    ConsultantGroupData: 'ConsultantGroupData',
    ConsultantIndustryData: 'ConsultantIndustryData',
    ConsultantLevelBase: 'ConsultantLevelBase',
    ConsultantLevelData: 'ConsultantLevelData',
    CurrencyRateData: 'CurrencyRateData',
    JobApplicationData: 'JobApplicationData',
    JobApplicationStatusData: 'JobApplicationStatusData',
    JobOpportunityData: 'JobOpportunityData',
    MeData: 'MeData',
    PageInfoFullData: 'PageInfoFullData',
    PageInfoShortData: 'PageInfoShortData',
    PublicWorkspaceSettingsData: 'PublicWorkspaceSettingsData',
    SubscriptionPackageData: 'SubscriptionPackageData',
    UserData: 'UserData',
    EngagementRoleStatusCPData: 'EngagementRoleStatusCPData',
    EngagementStatusCPData: 'EngagementStatusCPData',
    TimesheetDailyRecordData: 'TimesheetDailyRecordData',
    WorkspaceBaseData: 'WorkspaceBaseData',
    WorkspaceData: 'WorkspaceData',
    WorkspaceInviteData: 'WorkspaceInviteData',
    ClientData: 'ClientData',
    WorkspaceClientsData: 'WorkspaceClientsData',
    ClientContactData: 'ClientContactData',
    ClientContractProjectData: 'ClientContractProjectData',
    ClientContractData: 'ClientContractData',
    ConsultantAutocompleteData: 'ConsultantAutocompleteData',
    ConsultantToBeOnboardedData: 'ConsultantToBeOnboardedData',
    InvoiceConsultantData: 'InvoiceConsultantData',
    ConsultantContractData: 'ConsultantContractData',
    ConsultantContractProjectData: 'ConsultantContractProjectData',
    ConsultantContractsTableData: 'ConsultantContractsTableData',
    GenerateDraftContractData: 'GenerateDraftContractData',
    ConsultantDocumentData: 'ConsultantDocumentData',
    ConsultantLinkData: 'ConsultantLinkData',
    CountryData: 'CountryData',
    DocumentTypeData: 'DocumentTypeData',
    EntityPreviewData: 'EntityPreviewData',
    ExpenseCategoryData: 'ExpenseCategoryData',
    BudgetOverviewData: 'BudgetOverviewData',
    ExpenseCategoryOverviewData: 'ExpenseCategoryOverviewData',
    ExpensesOverviewData: 'ExpensesOverviewData',
    ProjectRoleOverviewData: 'ProjectRoleOverviewData',
    ProjectRolesOverviewData: 'ProjectRolesOverviewData',
    FinDashboardProjectData: 'FinDashboardProjectData',
    FinDashboardProjectClientData: 'FinDashboardProjectClientData',
    FinDashboardWorkspaceClientData: 'FinDashboardWorkspaceClientData',
    FinDashboardPeriodData: 'FinDashboardPeriodData',
    FinDashboardGroupValueData: 'FinDashboardGroupValueData',
    FinDashboardTotalData: 'FinDashboardTotalData',
    FinDashboardGroupData: 'FinDashboardGroupData',
    FinDashboardReportBundleData: 'FinDashboardReportBundleData',
    IndustryData: 'IndustryData',
    InitialDAContractDataFields: 'InitialDAContractDataFields',
    LegalEntityData: 'LegalEntityData',
    NoteBaseData: 'NoteBaseData',
    ClientProjectsScenarioData: 'ClientProjectsScenarioData',
    ClientProjectsData: 'ClientProjectsData',
    CommonProjectFields: 'CommonProjectFields',
    ProjectAutocompleteData: 'ProjectAutocompleteData',
    ProjectData: 'ProjectData',
    ProjectsTableScenarioData: 'ProjectsTableScenarioData',
    ProjectsData: 'ProjectsData',
    ProjectDeliverableData: 'ProjectDeliverableData',
    ProjectExpenseData: 'ProjectExpenseData',
    InvoiceRecordData: 'InvoiceRecordData',
    InvoiceRoleExtraDataData: 'InvoiceRoleExtraDataData',
    ProjectInvoiceData: 'ProjectInvoiceData',
    ProjectMilestoneData: 'ProjectMilestoneData',
    ConsultantProjectRoleProjectData: 'ConsultantProjectRoleProjectData',
    ConsultantProjectRoleData: 'ConsultantProjectRoleData',
    ProjectInvoiceRoleData: 'ProjectInvoiceRoleData',
    ProjectRoleDayWorkPlanData: 'ProjectRoleDayWorkPlanData',
    ProjectRoleLoadedLevelData: 'ProjectRoleLoadedLevelData',
    ProjectRoleData: 'ProjectRoleData',
    ProjectRoleOnboardingStateData: 'ProjectRoleOnboardingStateData',
    ProjectScenarioData: 'ProjectScenarioData',
    ProjectScenarioFullData: 'ProjectScenarioFullData',
    ProjectScenarioRequiredData: 'ProjectScenarioRequiredData',
    TimesheetWeeklyRecordData: 'TimesheetWeeklyRecordData',
    ProjectTimesheetAnnotationData: 'ProjectTimesheetAnnotationData',
    TimesheetProjectRoleData: 'TimesheetProjectRoleData',
    ProjectTimesheetData: 'ProjectTimesheetData',
    ProjectTimesheetPlannerBundleData: 'ProjectTimesheetPlannerBundleData',
    TimesheetDailyRecordExtendedData: 'TimesheetDailyRecordExtendedData',
    ProjectsOverviewData: 'ProjectsOverviewData',
    PurchaseOrderData: 'PurchaseOrderData',
    RecipientSnapshotData: 'RecipientSnapshotData',
    SupplierSnapshotData: 'SupplierSnapshotData',
    WorkspaceUserClientData: 'WorkspaceUserClientData',
    WorkspaceUserConsultantData: 'WorkspaceUserConsultantData',
    WorkspaceUserData: 'WorkspaceUserData',
    UserProfileData: 'UserProfileData',
    TimelineDateData: 'TimelineDateData',
    UtilisationConsultantData: 'UtilisationConsultantData',
    UtilisationProjectRoleData: 'UtilisationProjectRoleData',
    UtilisationTimesheetData: 'UtilisationTimesheetData',
    ApproveEngagementStatusData: 'ApproveEngagementStatusData',
    EngagementRoleStatusData: 'EngagementRoleStatusData',
    EngagementStatusData: 'EngagementStatusData',
    WorkspaceContractTemplateData: 'WorkspaceContractTemplateData',
    WorkspaceInvoiceHistoricalRecordData: 'WorkspaceInvoiceHistoricalRecordData',
    WorkspaceLocationData: 'WorkspaceLocationData',
    WorkspacePublicHolidayData: 'WorkspacePublicHolidayData',
    WorkspaceSettingsData: 'WorkspaceSettingsData'
  }
}