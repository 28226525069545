import { gql } from '@apollo/client';
import TimesheetDailyRecordFragment from './TimesheetDailyRecordFragment';

const EngagementRoleStatusFragmentCP = gql`
  fragment EngagementRoleStatusCPData on EngagementRoleStatus {
    status
    clientName
    projectName
    roleName
    roleId
    plannedUnits
    actualUnits
    unitType
    rejectionReason
    reason
    comment
    dailyRecords {
      ...TimesheetDailyRecordData
    }
  }
  ${TimesheetDailyRecordFragment}
`;

const EngagementStatusFragmentCP = gql`
  fragment EngagementStatusCPData on EngagementStatus {
    consultantName
    workspaceIdentifier
    weekName
    roles {
      ...EngagementRoleStatusCPData
    }
  }
  ${EngagementRoleStatusFragmentCP}
`;

export default EngagementStatusFragmentCP;
