import { gql } from '@apollo/client';
import ConsultantFullFragment from 'Shared/api/fragments/Consultant/ConsultantFullFragment';
import ConsultantAvailabilityFragment from 'Shared/api/fragments/ConsultantAvailability/ConsultantAvailabilityFragment';
import ConsultantBusinessInfoFragment from 'Shared/api/fragments/ConsultantBusinessInfo/ConsultantBusinessInfoFragment';
import ConsultantFunctionalRoleFragment from 'Shared/api/fragments/ConsultantFunctionalRole/ConsultantFunctionalRoleFragment';
import ConsultantIndustryFragment from 'Shared/api/fragments/ConsultantIndustry/ConsultantIndustryFragment';
import EngagementStatusFragmentCP from '../fragments/WeeklyEngagementStatus/EngagementStatusFragment';

export const GET_CONSULTANT_FUNCTIONAL_ROLES = gql`
  query consultantFunctionalRoles {
    consultantFunctionalRoles {
      ...ConsultantFunctionalRoleData
    }
  }
  ${ConsultantFunctionalRoleFragment}
`;

export const GET_CONSULTANT_INDUSTRIES = gql`
  query consultantIndustries {
    consultantIndustries {
      ...ConsultantIndustryData
    }
  }
  ${ConsultantIndustryFragment}
`;

export const GET_CONSULTANT = gql`
  query consultant($consultantId: ID!) {
    consultant(consultantId: $consultantId) {
      ...ConsultantFullData
    }
  }
  ${ConsultantFullFragment}
`;

export const GET_TALENT_MARKETPLACE_CONSULTANT = gql`
  query talentMarketplaceConsultant($consultantId: ID!) {
    talentMarketplaceConsultant(consultantId: $consultantId) {
      ...ConsultantFullData
    }
  }
  ${ConsultantFullFragment}
`;

export const GET_CONSULTANT_BUSINESS_INFO = gql`
  query consultantBusinessInfo($consultantId: ID!) {
    consultantBusinessInfo(consultantId: $consultantId) {
      ...ConsultantBusinessInfoData
    }
  }
  ${ConsultantBusinessInfoFragment}
`;

export const GET_CONSULTANT_AVAILABILITY = gql`
  query consultantAvailability($consultantId: ID!) {
    consultantAvailability(consultantId: $consultantId) {
      ...ConsultantAvailabilityData
    }
  }
  ${ConsultantAvailabilityFragment}
`;

export const GET_CONSULTANT_WEEKLY_ENGAGEMENT_STATUS = gql`
  query consultantWeeklyEngagementStatus($consultantId: ID!, $weekStartDate: LocalDate!) {
    consultantWeeklyEngagementStatus(consultantId: $consultantId, weekStartDate: $weekStartDate) {
      ...EngagementStatusCPData
    }
  }
  ${EngagementStatusFragmentCP}
`;