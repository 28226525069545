import { gql } from '@apollo/client';

const TimesheetDailyRecordFragment = gql`
  fragment TimesheetDailyRecordData on TimesheetDailyRecord {
    date
    plannedUnits
    actualUnits
    isInvoiced
  }
`;

export default TimesheetDailyRecordFragment;
