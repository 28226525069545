import { gql } from '@apollo/client';

const ConsultantBusinessInfoFragment = gql`
  fragment ConsultantBusinessInfoData on ConsultantBusinessInfo {
    id
    ogId
    verifiedAt
    verifiedByUser {
      firstName
      lastName
    }
    fullName
    phone
    email
    deliveryAddress
    passportFileUrl
    supplierName
    supplierAddress
    registrationNumber
    vat
    taxPercentage
    taxLabel
    professionalLiabilityInsuranceUrl
    publicLiabilityInsuranceUrl
    iban
    swift
    isVerified
    changeRequestMessage
  }
`;

export default ConsultantBusinessInfoFragment;
