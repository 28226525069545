/* eslint-disable @typescript-eslint/no-unused-vars */
const {
  red,
  volcano,
  gold,
  yellow,
  lime,
  green,
  cyan,
  blue,
  geekblue,
  purple,
  orange,
  magenta,
  grey,
// eslint-disable-next-line @typescript-eslint/no-var-requires
} = require('@ant-design/colors');

const neutral = ['#ffffff', '#fafafa', '#f5f5f5', '#f0f0f0', '#d9d9d9', '#bfbfbf', '#8c8c8c', '#595959', '#434343', '#262626', '#1f1f1f', '#141414', '#000000']; // these colors are in ant design docs and figma but not in the npm package
const extraWhite = ['#FCFCFC', '#F0F2F5']; // some additional custom colors
const font = "Roboto, '-apple-system', BlinkMacSystemFont, 'Helvetica Neue', Arial, sans-serif";

module.exports = {
  font,
  page: {
    verticalPadding: 18,
    horizontalPadding: 24,
    background: {
      default: neutral[0],
      secondary: extraWhite[1],
    },
    secondary: {
      width: {
        default: '798px',
        lg: '1180px',
        full: '100%',
      },
    },
  },
  drawer: {
    width: {
      default: 448,
      lg: 780,
    },
    body: {
      padding: '24px 24px 8px',
    },
  },
  avatar: {
    colors: {
      blue: {
        font: blue[6],
        bg: blue[2],
      },
      magenta: {
        font: magenta[6],
        bg: magenta[2],
      },
      purple: {
        font: purple[6],
        bg: purple[2],
      },
      gold: {
        font: gold[6],
        bg: gold[2],
      },
      cyan: {
        font: cyan[6],
        bg: cyan[2],
      },
      green: {
        font: green[6],
        bg: green[2],
      },
    },
  },
  spin: {
    size: {
      small: 14,
      default: 24,
      large: 36,
    },
  },
  form: {
    field: {
      marginBottom: 24,
    },
  },
  icon: {
    color: {
      warning: gold[4],
      primary: blue[5],
      secondary: grey[2],
    },
  },
  sider: {
    width: 66,
    background: {
      light: neutral[2],
    },
  },
  pageSider: {
    openWidth: 270,
    collapsedWidth: 47,
    background: {
      light: neutral[1],
    },
    siderTitle: {
      fontSize: '16px',
    },
    siderIconSize: 21,
  },
  menu: {
    collapsedWidth: 66,
  },
  typography: {
    title: {
      fontWeight: 700,
      marginBottom: 0,
      marginTop: 0,
    },
    paragraph: {
      lineHeight: {
        default: '16px',
        medium: '22px',
      },
    },
    text: {
      fontWeight: {
        slightlybold: 600,
        stronger: 700,
        extrabold: 900
      },
    },
    colors: {
      primary: blue[5],
      white: neutral[0],
      grey6: neutral[5],
      grey7: neutral[6],
      grey8: neutral[7],
      grey2: neutral[1],
      grey5: neutral[4],
      black: neutral[12],
      red: red[6],
      // ... add more colors if needed
    }
  },
  input: {
    affixMargin: '8px',
    addon: {
      background: 'transparent',
    },
  },
  dropdown: {
    custom: {
      width: {
        // it's not clear what other sizes we may have in designs
        // so it can be changed in future
        s: 250,
        m: 346,
      },
    },
  },
  steps: {
    title: {
      lineHeight: '32px',
    },
  },
  layout: {
    background: neutral[0],
  },
  collapse: {
    content: {
      background: neutral[0],
    },
    header: {
      background: extraWhite[0],
    },
  },
  size: {
    xxs: '4px',
    xs: '8px',
    s: '12px',
    m: '16px',
    l: '20px',
    xl: '24px',
    xxl: '28px',
    xxxl: '32px',
    xxxxl: '40px',
    xxxxxl: '64px',
    xxxxxxl: '72px',
    xxxxxxxl: '80px',
    xxxxxxxxl: '96px',
  },
  fontSize: {
    xxxs: '8px',
    xxs: '10px',
    xs: '12px',
    s: '14px',
    m: '16px',
    l: '20px',
    xl: '24px',
  },
  modal: {
    confirm: {
      okBtn: {
        maxWidth: '230px',
      },
    },
  },
  projects: {
    stageColors: {
      contracting: 'warning',
      proposal: 'default',
      running: 'success',
      default: 'default',
    },
  },
  project: {
    projectStages: {
      activeStageColor: green[6],
      inactiveStageColor: red[4],
      neutralStageColor: neutral[3],
      lostStageColor: red[4],
      lightFontColor: neutral[0],
      darkFontColor: grey[8],
    },
    timeSheetPeriodColors: {
      present: blue[5],
      past: blue[1],
      future: neutral[4],
    },
    projectRoleDeltaColors: {
      ok: 'transparent',
      overrun: magenta[0],
      underrun: green[0],
    },
    scenario: {
      colors: {
        hover: neutral[2],
      },
    },
  },
  upload: {
    content: {
      padding: '8px',
    },
    dropIcon: {
      font: {
        size: '38px',
      },
    },
    fileIcon: {
      font: {
        size: '32px',
      },
    },
  },
  table: {
    summary: {
      background: neutral[1],
    },
  },
  tabs: {
    background: neutral[0],
  },
  // TODO: research how we can extract antd colors and talk to Sam about color palette
  // https://github.com/ant-design/ant-design/blob/master/components/style/color/colors.less
  colors: {
    gold: {
      base: gold[5],
      100: gold[0],
      200: gold[1],
      300: gold[2],
      400: gold[3],
      500: gold[4],
      600: gold[5],
      700: gold[6],
      800: gold[7],
    },
    error: {
      base: red[6],
    },
    white: {
      base: neutral[0],
    },
    black: {
      base: neutral[12],
    },
    blue: {
      base: blue[3],
      100: blue[0],
      200: blue[1],
      300: blue[2],
      400: blue[3],
      500: blue[4],
      600: blue[5],
      700: blue[6],
      800: blue[7],
      900: blue[8],
      1000: blue[9],
    },
    red: {
      base: red[6],
      100: red[0],
      200: red[1],
      300: red[2],
      400: red[3],
      500: red[4],
      600: red[5],
      700: red[6],
      800: red[7],
      900: red[8],
      1000: red[9],
    },
    gray: {
      base: neutral[5],
      100: grey[0],
      200: grey[1],
      300: grey[2],
      400: grey[3],
      500: grey[4],
      600: grey[5],
      700: grey[6],
      800: grey[7],
      900: grey[8],
      1000: grey[9],
    },
    neutral: {
      base: neutral[0],
      100: neutral[0],
      200: neutral[1],
      300: neutral[2],
      400: neutral[3],
      500: neutral[4],
      600: neutral[5],
      700: neutral[6],
      800: neutral[7],
    },
    orange: {
      100: orange[0],
      200: orange[1],
      300: orange[2],
      400: orange[3],
      500: orange[4],
      600: orange[5],
      700: orange[6],
      800: orange[7],
      900: orange[8],
      1000: orange[9],
    },
    green: {
      100: green[0],
      200: green[1],
      300: green[2],
      400: green[3],
      500: green[4],
      600: green[5],
      700: green[6],
      800: green[7],
      900: green[8],
      1000: green[9],
    },
  },
  deliverable: {
    stageColors: {
      working: 'warning',
      contracting: 'default',
      accepted: 'success',
      rejected: 'error',
      underReview: 'warning',
      default: 'default',
    },
  },
  contract: {
    stageColors: {
      created: 'processing',
      sent: 'warning',
      voided: 'error',
      declined: 'error',
      completed: 'blue',
      expired: 'volcano',
      signed: 'success',
      deleted: 'red',
      delivered: 'gold',
      default: 'default',
    },
    statusColors: {
      active: 'green',
      canceled: 'orange',
      changed: 'geekblue',
      expired: 'red',
      pending: 'purple',
      default: 'default',
    },
  },
  consultant: {
    document: {
      stageColors: {
        active: 'success',
        expired: 'error',
        default: 'default',
      },
    },
    group: {
      colors: [
        'gold',
        'yellow',
        'lime',
        'cyan',
        'blue',
        'geekblue',
        'purple',
        'magenta',
      ],
    },
  },
  activityTemplates: {
    content: {
      background: neutral[0],
    },
    activity: {
      border: {
        color: {
          error: red[4],
          active: blue[4],
        },
      },
    },
  },
  badge: {
    primary: {
      background: blue[0],
      text: blue[5],
    },
    success: {
      background: green[0],
      text: green[5],
    },
    danger: {
      background: red[0],
      text: red[5],
    },
  },
  richTextEditor: {
    contentStyle: `
      body {
        font-family: ${font};
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        line-height: 1.4;
      }
      .tox-tinymce-inline, .tox-menu { z-index: 1000; }
      .mce-content-body { border: 0; outline: 0; }
    `,
    height: 400,
  },
  settings: {
    content: {
      width: 400,
    },
  },
  financialsOverview: {
    controls: {
      rangePickerWidth: 240,
    },
    table: {
      columnHeader: {
        width: 250,
        height: 64,
        padding: 16,
      },
      rowHeader: {
        width: 360,
        extendedWidth: 720,
        narrowWidth: 120,
        height: 64,
      },
      cell: {
        padding: 16,
        bgColor: {
          period: neutral[1],
          total: neutral[1],
          project: neutral[0],
          header: neutral[0],
          client: extraWhite[1],
          default: 'transparent',
        },
      },
      border: {
        color: neutral[3],
        width: 1,
      },
    },
  },
  workspaceProfileDropdown: {
    width: 250,
    workspaceItem: {
      hover: {
        color: neutral[2],
      },
    },
  },
  financialPlanner: {
    spaceBetweenTotalBoxes: 24,
  },
  dateFormats: {
    humanReadable: 'MMM dd, yyyy',
    humanReadableFullMonth: 'MMMM dd, yyyy',
    DateMonthYear: 'dd-MM-yyyy',
    YearMonthDay: 'yyyy-MM-dd',
    dayMonthDate: 'EE: MMM-dd',
    monthDate: 'MMM dd',
    monthAndYear: 'MMM yyyy',
  },
  intlDateFormats: {
    // Options:
    // https://formatjs.io/docs/react-intl/components#formatteddate
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
    humanReadable: { year: 'numeric', month: 'short', day: 'numeric' },
    dateMonthYear: { dateStyle: 'medium' },
    monthAndYear: { year: 'numeric', month: 'short' },
    monthAndDate: { month: 'short', day: 'numeric' }
  },
  resourceCollectives: {
    sider: {
      width: 320,
      background: neutral[0],
    },
    content: {
      width: 960,
    },
  },
  billing: {
    invoice: {
      marginTop: 30
    },
    InvoiceStatusColors: {
      paid: 'green',
      awaitingPayment: 'orange',
      partallyPaid: 'orange',
      credited: 'red',
      draft: grey[100],
    },
    invoicingColors: {
      invoiced: green[500],
      uninvoiced: orange[500],
    },
    generatedInvoiceTable: {
      headingStyle: {
        fontWeight: 'bold',
        paddingBottom: '20px',
      },
      exclamationCircleStyle: {
        color: orange[5],
        marginRight: '10px',
        fontSize: '14px',
      },
      objectionStyle: {
        color: orange[5],
      },
      objectionSuggestionStyle: {
        color: grey[4],
        fontSize: '12px',
        paddingBottom: '10px',
        marginLeft: '2.5vh'
      },
      topObjectionDividerStyle: {
        marginLeft: '2.5vh',
        marginTop: 0,
        marginBottom: '10px'
      },
      bottomObjectionDividerStyle: {
        marginLeft: '2.5vh',
      },
    },
    billingTotal: {
      footerStyle: {
        alignSelf: 'flex-end',
        minWidth: '20%',
        padding: '20px 5%',
        alignContent: 'flex-start'
      },
      rowStyle: {
        display: 'flex',
        paddingTop: '5px',
        alignItems: 'center',
        height: '30px',
        width: '345px',
      },
      leftValueStyle: {
        flex: 1,
        paddingLeft: '15px',
        color: 'grey',
        width: '150px',
      },
      rightValueStyle: {
        flex: 1,
        marginLeft: '25%',
        color: 'grey',
        width: '150px',
      },
      totalLeftValueStyle: {
        flex: 1,
        paddingLeft: '15px'
      },
      totalRightValueStyle: {
        flex: 1,
        marginLeft: '25%'
      },
      inputBoxStyle: {
        maxWidth: '80px',
        marginLeft: '5px'
      },
      vatValueStyle: {
        marginLeft: '5px'
      },
    },
    pdfView: {
      greyText: {
        color: grey[4],
      },
      oneFlex: {
        display: 'flex',
        flex: 1,
      },
      headerSectionStyle: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
      },
      headerTextStyle: {
        display: 'flex',
        flex: 1,
        fontWeight: '700',
        fontSize: '14px',
        lineHeight: '22px',
      },
      headerSmallTextStyle: {
        fontWeight: '500',
        fontSize: '8px',
        lineHeight: '13px',
      },
      headerLogoStyle: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
        paddingRight: '10px',
      },
      billSectionStyle: {
        display: 'flex',
        flex: 1,
        alignItems: 'flex-start',
      },
      billBox: {
        maxWidth: '45%',
        marginRight: '100px'
      },
      billHeader: {
        color: neutral[12],
        marginBottom: '12px',
        fontWeight: '800',
      },
      tableSectionStyle: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
      },
      lineKeyStyle: {
        marginRight: '22px'
      },
      tableColumnHeader: {
        fontWeight: '700'
      },
      dividerStyle: {
        marginTop: '10px',
        marginBottom: '15px'
      },
      totalAreaStyle: {
        display: 'flex',
        flex: 1,
        width: '100%',
      },
    },
    gridFormStyle : { border: '1px solid lightgrey', padding:'20px', marginBottom:'50px', backgroundColor : neutral[0] },
    bordeRadius: '2px',
  },
  gradientOverlay: {
    grey0deg: '0deg, rgba(0, 0, 0, 0.54), rgba(0, 0, 0, 0.54)'
  }
};
